<template>
  <div>
    <label v-if="label" class="text-capitalize font-weight-bold">Marcas</label>
    <v-textarea
      v-model="internalValue"
      outlined
      counter
      clearable
      @blur="emitBlur"
      clear-icon="mdi-close-circle"
      @input="updateValue"></v-textarea>
  </div>
</template>

<script>
export default {
  name: 'RichTextEditor',
  props: {
    label: { type: String, default: null },
    initialValue: { type: String, default: '' },
  },
  data() {
    return {
      internalValue: this.initialValue,
    }
  },
  watch: {
    initialValue(newValue) {
      this.internalValue = newValue
    },
  },
  methods: {
    updateValue() {
      console.log('updateValue', this.internalValue)
      this.$emit('input', this.internalValue)
    },
    emitBlur() {
      this.$emit('blur')
    },
  },
}
</script>

<style scoped></style>
