var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.selected.length > 0 && _vm.ui.step == 0)?_c('v-card',{staticClass:"mb-3",attrs:{"outlined":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('h3',{staticClass:"pl-3"},[_vm._v("\n          "+_vm._s(_vm.selected.length || 0)+"\n        ")])]),_vm._v(" "),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Elementos seleccionados")]),_vm._v(" "),_c('v-list-item-subtitle',[_vm._v("Continúa para crear un nuevo lote")])],1),_vm._v(" "),_c('v-list-item-action',[_c('v-btn',{attrs:{"block":"","small":"","depressed":"","color":"success"},on:{"click":_vm.nextStep}},[_vm._v("\n          Crear con la selección\n        ")])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.ui.step == 0)?[_c('v-card',[_c('v-data-table',{ref:"datatable",attrs:{"item-key":"uuid","show-select":"","items":_vm.rows,"headers":[
          {
            text: 'Estado',
            value: 'estado',
            align: 'left',
            cellClass: 'subData',
            width: 100,
          },
          {
            text: 'Fecha creación',
            value: 'dates',
            cellClass: 'subData',
            width: 150,
          },
          {
            text: 'Alcance',
            value: 'nombre_alcance',
            cellClass: 'subData',
            width: 150,
          },
          {
            text: 'Expediente',
            value: 'codigo_expediente',
            cellClass: 'subData',
            width: 150,
          },
          { text: 'Cliente', value: 'client' } ],"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"page":_vm.currentPage,"options":_vm.options,"sort-by":"createdAt","sort-desc":"","loading":_vm.ui.loading},on:{"update:page":function($event){_vm.currentPage=$event},"update:options":[function($event){_vm.options=$event},_vm.updateOptions]},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.getName(item))+"\n        ")]}},{key:"item.dates",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n          "+_vm._s(_vm.$moment(item.createdAt).format('DD [de] MMMM, YYYY'))+"\n        ")]}}],null,false,2175343086),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]:_vm._e(),_vm._v(" "),(_vm.ui.step == 1)?[_c(_vm.formComponent,{ref:"form",tag:"component",attrs:{"type":_vm.type,"_batch":_vm.batch,"_selected":_vm.selected,"is_revision":_vm.revision},on:{"lotes:reset":function($event){_vm.ui.step = 0
        _vm.batch = []
        _vm.selected = []}}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }