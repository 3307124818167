import { uuid } from 'vue-uuid'
import moment from 'moment'

export const state = () => ({
  list: [],
  tab: 0,
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return state.list
  },
  SET_LIST(state, items) {
    state.list = items
  },
  CHANGE_TAB(state, tab) {
    state.tab = tab
  },
}
//dispatch actions
export const actions = {
  async list({ rootState, commit }, uuid) {
    rootState.loading = true
    const body = {}
    if (uuid) body.expediente = uuid
    const res = await this.$axios.put('revisiones', body)
    rootState.loading = false
    if (res) {
      const data = res.data
      commit('SET_LIST', data)
      return data
    }
  },
  async customList({ rootState, commit }, payload) {
    rootState.loading = true
    const body = {}
    //serverside pagination
    if (payload && payload.limit) body.limit = payload.limit
    if (payload && payload.page) body.page = payload.page
    if (payload && payload.sortBy && payload.sortDesc)
      body.sort = { field: payload.sortBy[0], order: payload.sortDesc[0] ? 'desc' : 'asc' }
    if (payload && payload.estado) body.estado = payload.estado
    const res = await this.$axios.put('revisiones', body)
    rootState.loading = false
    if (res) {
      const data = res.data
      commit('SET_LIST', data)
      return data
    }
  },

  async get({ rootState }, uuid) {
    rootState.loading = true
    const res = await this.$axios.get(`revisiones/${uuid}`)
    rootState.loading = false
    if (res) return res.data
  },

  async create({ rootState }, revision) {
    rootState.loading = true
    // const body = {
    //   uuid: uuid.v4(),
    //   auditoria: revision.auditoria,
    //   auditor: revision.auditor,
    // }
    await this.$axios.post('revisiones', revision)
    rootState.loading = false
  },

  async update({ rootState }, revision) {
    rootState.loading = true

    // const body = {
    //   revision: revision.uuid,
    //   auditoria: revision.auditoria,
    //   auditor: revision.auditor,
    // }
    revision.revision = revision.uuid // 🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️🤷‍♀️

    await this.$axios.patch(`revisiones/${revision.uuid}`, revision)
    rootState.loading = false
  },

  async proponer({ rootState }, revision) {
    rootState.loading = true
    const body = {
      proposicion: revision.proposicion,
      fecha_proposicion: revision.fecha_proposicion,
      auditor_1: revision.auditor_1,
      en_calidad_de_auditor_1: revision.en_calidad_de_auditor_1,
      observaciones_auditor_1: revision.observaciones_auditor_1,
      auditor_2: revision.auditor_2,
      en_calidad_de_auditor_2: revision.en_calidad_de_auditor_2,
      observaciones_auditor_2: revision.observaciones_auditor_2,
      revision: revision.uuid,
    }
    await this.$axios.patch(`revisiones/${revision.uuid}/proponer`, body)
    rootState.loading = false
  },

  async cambiarEstado({ rootState }, revision) {
    rootState.loading = true
    const body = {
      revision: revision.uuid,
    }
    await this.$axios.patch(`revisiones/${revision.uuid}/revisada`, body)
    rootState.loading = false
  },

  async delete({ rootState }, revision) {
    rootState.loading = true
    await this.$axios.delete(`revisiones/${revision.uuid}`)
    rootState.loading = false
    return data
  },
}
