import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/alcances.js'), 'alcances.js')
  resolveStoreModules(require('../store/auditores.js'), 'auditores.js')
  resolveStoreModules(require('../store/auditorias_ampliacion.js'), 'auditorias_ampliacion.js')
  resolveStoreModules(require('../store/auditorias-informes.js'), 'auditorias-informes.js')
  resolveStoreModules(require('../store/auditorias.js'), 'auditorias.js')
  resolveStoreModules(require('../store/autoevaluacion.js'), 'autoevaluacion.js')
  resolveStoreModules(require('../store/bloques.js'), 'bloques.js')
  resolveStoreModules(require('../store/cargas.js'), 'cargas.js')
  resolveStoreModules(require('../store/certificados.js'), 'certificados.js')
  resolveStoreModules(require('../store/checklists.js'), 'checklists.js')
  resolveStoreModules(require('../store/clientes_contactos.js'), 'clientes_contactos.js')
  resolveStoreModules(require('../store/clientes_instalaciones.js'), 'clientes_instalaciones.js')
  resolveStoreModules(require('../store/clientes_tecnicos.js'), 'clientes_tecnicos.js')
  resolveStoreModules(require('../store/clientes.js'), 'clientes.js')
  resolveStoreModules(require('../store/compartir.js'), 'compartir.js')
  resolveStoreModules(require('../store/competencias.js'), 'competencias.js')
  resolveStoreModules(require('../store/componentes.js'), 'componentes.js')
  resolveStoreModules(require('../store/comunicaciones_externas.js'), 'comunicaciones_externas.js')
  resolveStoreModules(require('../store/comunicaciones.js'), 'comunicaciones.js')
  resolveStoreModules(require('../store/comunidad.js'), 'comunidad.js')
  resolveStoreModules(require('../store/contactos.js'), 'contactos.js')
  resolveStoreModules(require('../store/context.js'), 'context.js')
  resolveStoreModules(require('../store/coplaca.js'), 'coplaca.js')
  resolveStoreModules(require('../store/custom_variables.js'), 'custom_variables.js')
  resolveStoreModules(require('../store/delegaciones.js'), 'delegaciones.js')
  resolveStoreModules(require('../store/dictamenes.js'), 'dictamenes.js')
  resolveStoreModules(require('../store/documentos.js'), 'documentos.js')
  resolveStoreModules(require('../store/dropdowns.js'), 'dropdowns.js')
  resolveStoreModules(require('../store/empresas.js'), 'empresas.js')
  resolveStoreModules(require('../store/estaticos.js'), 'estaticos.js')
  resolveStoreModules(require('../store/etiquetas.js'), 'etiquetas.js')
  resolveStoreModules(require('../store/eventos.js'), 'eventos.js')
  resolveStoreModules(require('../store/expedientes.js'), 'expedientes.js')
  resolveStoreModules(require('../store/formaciones.js'), 'formaciones.js')
  resolveStoreModules(require('../store/formato-plantillas.js'), 'formato-plantillas.js')
  resolveStoreModules(require('../store/formatos.js'), 'formatos.js')
  resolveStoreModules(require('../store/gestor_documental.js'), 'gestor_documental.js')
  resolveStoreModules(require('../store/globalgap.js'), 'globalgap.js')
  resolveStoreModules(require('../store/grupo_contactos.js'), 'grupo_contactos.js')
  resolveStoreModules(require('../store/grupo_solicitudes.js'), 'grupo_solicitudes.js')
  resolveStoreModules(require('../store/grupos_alcances.js'), 'grupos_alcances.js')
  resolveStoreModules(require('../store/grupos.js'), 'grupos.js')
  resolveStoreModules(require('../store/incidencias.js'), 'incidencias.js')
  resolveStoreModules(require('../store/incompatibilidades.js'), 'incompatibilidades.js')
  resolveStoreModules(require('../store/informes.js'), 'informes.js')
  resolveStoreModules(require('../store/iteraciones.js'), 'iteraciones.js')
  resolveStoreModules(require('../store/layout.js'), 'layout.js')
  resolveStoreModules(require('../store/lista_comprobacion.js'), 'lista_comprobacion.js')
  resolveStoreModules(require('../store/listas_validacion.js'), 'listas_validacion.js')
  resolveStoreModules(require('../store/localidades.js'), 'localidades.js')
  resolveStoreModules(require('../store/lotes.js'), 'lotes.js')
  resolveStoreModules(require('../store/marcas.js'), 'marcas.js')
  resolveStoreModules(require('../store/mensajes.js'), 'mensajes.js')
  resolveStoreModules(require('../store/metadata.js'), 'metadata.js')
  resolveStoreModules(require('../store/notificaciones.js'), 'notificaciones.js')
  resolveStoreModules(require('../store/offline.js'), 'offline.js')
  resolveStoreModules(require('../store/offlineMode.js'), 'offlineMode.js')
  resolveStoreModules(require('../store/permisos.js'), 'permisos.js')
  resolveStoreModules(require('../store/planificador.js'), 'planificador.js')
  resolveStoreModules(require('../store/plantillas-bloques.js'), 'plantillas-bloques.js')
  resolveStoreModules(require('../store/plantillas-checklist.js'), 'plantillas-checklist.js')
  resolveStoreModules(require('../store/plantillas.js'), 'plantillas.js')
  resolveStoreModules(require('../store/presupuestos.js'), 'presupuestos.js')
  resolveStoreModules(require('../store/public.js'), 'public.js')
  resolveStoreModules(require('../store/reportes.js'), 'reportes.js')
  resolveStoreModules(require('../store/revisiones.js'), 'revisiones.js')
  resolveStoreModules(require('../store/signatures.js'), 'signatures.js')
  resolveStoreModules(require('../store/sites.js'), 'sites.js')
  resolveStoreModules(require('../store/solicitudes.js'), 'solicitudes.js')
  resolveStoreModules(require('../store/territorios.js'), 'territorios.js')
  resolveStoreModules(require('../store/tipos_fichero.js'), 'tipos_fichero.js')
  resolveStoreModules(require('../store/tomas_muestra.js'), 'tomas_muestra.js')
  resolveStoreModules(require('../store/tramites.js'), 'tramites.js')
  resolveStoreModules(require('../store/user.js'), 'user.js')
  resolveStoreModules(require('../store/users.js'), 'users.js')
  resolveStoreModules(require('../store/zonas_visita.js'), 'zonas_visita.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
