<template>
  <div :class="{ unavailable: signature && $nuxt.isOffline && !signature.base64 }">
    <template v-if="options.type == 'croquis'"> Dibujo de referencia </template>

    <template v-else>
      Firma <strong>{{ options.created_name }}</strong> como
      {{ options.typeOfUser }}
    </template>

    <span
      style="
        display: block;
        color: rgb(146, 146, 146);
        font-size: 12px !important;
        white-space: nowrap;
        padding: 0px !important;
      ">
      <template v-if="signature">
        A {{ $moment(signature.created_at).format('D [de] MMMM, YYYY') }}
      </template>
      <template v-else> A {{ $moment().format('D [de] MMMM, YYYY') }} </template>
    </span>
    <div
      style="border-bottom: 1px dashed #ccc; padding-top: 7px; text-align: center"
      @click.stop="sign">
      &nbsp;

      <template v-if="signature">
        <img
          v-if="signature.base64"
          style="max-width: 95%; margin: 0 auto"
          :src="signature.base64"
          alt="firma" />

        <p v-if="$nuxt.isOffline && !signature.base64" style="font-weight: bold">
          Esta firma se realizó anteriormente.<br />
          No se puede mostrar sin conexión
        </p>

        <img
          style="max-width: 95%; margin: 0 auto"
          v-if="!$nuxt.isOffline"
          :src="signature.public_png || signature.public_url"
          alt="firma-online" />
      </template>
    </div>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\signatures\show.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 23rd August 2022
 * Last Modified: Fri May 05 2023
 **/

export default {
  name: 'SignatureShow',
  props: ['signed', 'options'],

  data: () => ({
    user: {},
    signature: false,

    ui: {
      valid: false,
      dialog: false,
      saving: false,
      loading: false,
    },
  }),

  computed: {},

  methods: {
    //+-------------------------------------------------
    // sign()
    // emits the signature event to open and display
    // the signature pad
    // -----
    // Created on Mon Aug 29 2022
    //+-------------------------------------------------
    sign() {
      if (this.signature && !this.options.can_replace) return
      
      this.$nuxt.$emit('signatures:open', {
        ...this.options,
      })
    },

    //+-------------------------------------------------
    // findSignature()
    // Searches this.signed prop for signatures that have
    // the same created_by as the current prop
    // -----
    // Created on Fri Sep 02 2022
    //+-------------------------------------------------
    findSignature(event) {
      let signature = false
      let createdBy = this.options.created_by
      if (this.signed && this.signed.length > 0) {
        signature = this.signed.find((s) => {
          return s.created_by == createdBy
        })
      } else if (this.signed?.public_url || this.signed?.public_png) {
        signature = this.signed
      }

      if (event) {
        if (event.data && event.data.created_by == createdBy) {
          signature = event.data
        }
      }

      if (signature) {
        this.display(signature)
      } else {
        console.warn('no sign',event)
      }
    },
    reset() {
      this.signature = false
    },
    //+-------------------------------------------------
    // display()
    // Retrieves the signature from data or events and show
    // -----
    // Created on Mon Aug 29 2022
    //+-------------------------------------------------
    display(body) {
      // var urlCreator = window.URL || window.webkitURL
      // var imageUrl = urlCreator.createObjectURL(body.data.file)
      // this.signature = imageUrl
      // //  document.querySelector("#image").src = imageUrl;

      if (body) this.signature = body
      if (body.length && body[0]) this.signature = body[0]
      if (body.data?.public_url) this.signature = body.data

      console.warn(body, this.signature)
      // debugger
    },

    async init() {
      this.user = this.$auth.user
      this.findSignature()

      this.$nuxt.$on('signatures:signed', (payload) => {
        console.warn('signatures:signed', payload)
        if(payload.data.type == 'croquis') this.signed = payload.data
        this.findSignature(payload)
        this.$emit('onSigned', payload)
      })
    },
  },

  mounted() {
    this.init()
  },

  beforeDestroy() {
    this.$nuxt.$off('signatures:signed')
  },
}
</script>

<style>
.unavailable {
  background: repeating-linear-gradient(
    -45deg,
    rgba(96, 108, 188, 0.25),
    rgba(96, 109, 188, 0.25) 10px,
    rgba(70, 82, 152, 0.25) 10px,
    rgba(70, 82, 152, 0.25) 20px
  );
  padding: 15px;
  border-radius: 6px;
}
</style>
