<template>
  <div>
    <v-card outlined>
      <v-card-title>
        {{ grid.title }}
        <v-spacer></v-spacer>

        <!-- Añadir Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              fab
              depressed
              color="success"
              class="white--text mr-2"
              v-on="on"
              :disabled="auditoriaCerrada || isDisabled"
              @click="editItem()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Añadir fila</span>
        </v-tooltip>

        <!-- Eliminar Fila -->
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              fab
              depressed
              color="error"
              class="white--text"
              v-on="on"
              :disabled="auditoriaCerrada || isDisabled"
              @click="removeRow()">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar fila</span>
        </v-tooltip>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <AgDatatable
          :headers="grid.columnDefs"
          :items="grid.rowData"
          :height="'65'"
          :rowSelection="'multiple'"
          sizeColumnsToFit
          hideOverlay
          emitClickRow
          @click-row="editItem($event)"
          @gridApi="onGridReady($event)" />
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="750px">
      <v-card v-if="dialog">
        <v-card-title primary-title> Datos Muestra </v-card-title>

        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <template v-if="!isQuesoManchego">
                <v-col cols="6" v-for="header in fields" :key="header.id">
                  <v-text-field
                    :label="header.texto"
                    v-model="item[header.valor]"
                    required
                    outlined
                    flat
                    dense
                    hide-details="auto"></v-text-field>
                </v-col>
              </template>

              <template v-if="isOceGlobal">
                <v-col cols="12">
                  <v-select
                    label="Parcelas"
                    :items="siteslist"
                    outlined
                    dense
                    hide-details="auto"
                    :item-text="nombreYLugar"
                    item-value="site_data"
                    autocomplete
                    @change="setSite"></v-select>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Cultivo"
                    v-model="item.cultivo"
                    disabled
                    outlined
                    flat
                    dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Municipio"
                    v-model="item.municipio"
                    disabled
                    outlined
                    flat
                    dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Polígono"
                    v-model="item.poligono"
                    disabled
                    outlined
                    flat
                    dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Parcela"
                    v-model="item.parcela"
                    disabled
                    outlined
                    flat
                    dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    label="Recinto"
                    v-model="item.recinto"
                    disabled
                    outlined
                    flat
                    dense
                    hide-details="auto"></v-text-field>
                </v-col>
              </template>

              <template v-if="isQuesoManchego">
                <v-col cols="12">
                  <h3>Acta toma de muestras</h3>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    label="Identificación de la muestra"
                    v-model="item.identificacion_muestra"
                    outlined
                    flat
                    dense
                    hide-details="auto"></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-dialog
                    ref="datepicker"
                    v-model="ui.showPicker"
                    :return-value.sync="item.fecha"
                    persistent
                    width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="$moment(item.fecha).format('D [de] MMMM, YYYY')"
                        label="Fecha de elaboración"
                        outlined
                        flat
                        dense
                        hide-details="auto"
                        readonly
                        v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>

                    <v-date-picker v-model="item.fecha" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="ui.showPicker = false"> Cancelar </v-btn>

                      <v-btn text color="primary" @click="$refs.datepicker.save(item.fecha)">
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="6">
                  <v-dialog
                    ref="datepickerB"
                    v-model="ui.showPickerB"
                    :return-value.sync="item.fechaMuestra"
                    persistent
                    width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="$moment(item.fechaMuestra).format('D [de] MMMM, YYYY')"
                        label="Fecha de la toma de muestra"
                        outlined
                        flat
                        dense
                        hide-details="auto"
                        readonly
                        v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>

                    <v-date-picker v-model="item.fechaMuestra" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="ui.showPickerB = false"> Cancelar </v-btn>

                      <v-btn text color="primary" @click="$refs.datepickerB.save(item.fechaMuestra)">
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- <v-col cols="6" v-else>
                  <v-text-field
                    label="Fecha de elaboración"
                    v-model="item.fecha"
                    outlined
                    flat
                    dense
                    :rules="[
                      (v) => !!v || 'Campo requerido',
                      (v) => {
                        const regex = /^\d{2}-\d{2}-\d{4}$/
                        return regex.test(v) || 'Formato incorrecto'
                      },
                    ]"
                    hide-details="auto"></v-text-field>
                </v-col> -->

                <v-col cols="6">
                  <SelectEstaticos
                    label="Seleccionar laboratorio"
                    v-if="auditoria.alcance && isQuesoManchego"
                    v-model="item.laboratorios"
                    :disabled="isDisabled"
                    :alcance="auditoria.alcance.uuid"
                    :estaticos="estaticos"
                    codigo="laboratorios"
                    outlined
                    required
                    dense
                    :multiple="true" />
                </v-col>

                <v-col cols="6">
                  <!-- <v-text-field
                    label="Análisis a realizar"
                    v-model="item.analisis"
                    outlined flat
                    dense
                    hide-details="auto"
                  ></v-text-field> -->
                  <SelectEstaticos
                    label="Seleccionar tipo de análisis"
                    v-model="item.analisis"
                    :disabled="isDisabled"
                    :alcance="auditoria.alcance.uuid"
                    :estaticos="estaticos"
                    outlined
                    codigo="tipos_analisis"
                    required
                    dense
                    :multiple="isQuesoManchego ? true : false" />
                </v-col>

                <v-col cols="2">
                  <v-checkbox
                    label="Cumple"
                    v-model="item.cumple"
                    outlined
                    flat
                    dense
                    hide-details="auto"></v-checkbox>
                </v-col>


                <v-col v-if="checklist && preguntas.length > 0">
                  <v-autocomplete
                    label="Pregunta relacionada"
                    v-model="item.pregunta"
                    outlined
                    :items="preguntas"
                    :item-text="(item) => stripHtml(item.titulo)"
                    item-value="uuid"
                    flat
                    dense
                    hide-details="auto"></v-autocomplete>
                </v-col>
                <v-col v-else>
                  <v-autocomplete
                    label="Pregunta relacionada"
                    v-model="item.pregunta"
                    outlined
                    :items="[{}]"
                    disabled
                    flat
                    dense
                    hide-details="auto"></v-autocomplete>
                    <span class="caption">No hay checklist asociados a la auditoria</span>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="item.observaciones"
                    label="Observaciones"
                    auto-grow
                    outlined></v-textarea>
                </v-col>
              </template>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text class="mr-3" @click="close()"> Cancelar </v-btn>
          <v-btn depressed color="primary" @click="saveItem()"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    \components\auditorias\muestras\DatosMuestra.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue Dec 27 2022
 **/

import Estados from '~/models/estados'
import AgDatatable from '~/components/AgDatatableOld'
import SelectEstaticos from '~/components/forms/SelectEstaticos'

export default {
  props: {
    value: { type: Array, default: () => [] },
    auditoria: { type: Object, default: () => {} },
    codigo: { type: String, default: () => '' },
    isDisabled: { type: Boolean, default: false },
    lote: { type: String, default: '' },
    tipos: { type: Array, default: () => [] },
    laboratorios: { type: Array, default: () => [] },
  },
  components: {
    AgDatatable,
    SelectEstaticos,
  },
  data: () => ({
    dialog: false,
    item: {
      fecha: new Date().toISOString().substr(0, 10),
      cumple: false,
    },
    fields: [],
    checklist: null,
    itemSelected: null,
    grid: { api: null, title: '', columnDefs: [], rowData: [] },

    ui: {
      showPicker: false,
      showPickerB: false,
    },
  }),
  watch: {
    value(val) {
      this.grid.rowData = val
      console.log('watch', val)
    },
  },
  computed: {
    preguntas() {
      let allowedCodes = ["12.01","12.02","12.03"] // Códigos de preguntas que se pueden seleccionar (QM)
      if (this.checklist) {
        return this.checklist.preguntas.filter((el) => !el.solo_titulo && allowedCodes.includes(el.codigo))
      } else {
        return []
      }
    },
    offlineMode() {
      return this.$store.getters['auditorias/offlineMode']
    },

    isOceGlobal() {
      return this.$auth.user.empresa.codigo == 'oceglobal'
    },

    isQuesoManchego() {
      return this.$auth.user.empresa.codigo == 'quesomanchego'
    },
    isCerticalidad() {
      return this.$auth.user.empresa.nombre == 'certicalidad'
    },

    auditoriaCerrada() {
      return (
        (this.auditoria &&
          this.auditoria.estado?.toUpperCase() === Estados.AUDITORIA_ESTADO.CERRADA) ||
        (this.auditoria && this.auditoria.estado?.toUpperCase() == 'REALIZADA')
      )
    },
    setIMuestra() {
      console.warn('here', this.lote)
      if (this.isQuesoManchego) this.item.identificacion_muestra = this.lote
      this.$forceUpdate()
    },
    siteslist() {
      if (this.auditoria && this.auditoria.sites) {
        //delete sites que el tipo no sea parcela
        const sites = this.auditoria.sites.filter((el) => el.tipo === 'parcela')

        return sites
      }
    },
  },
  methods: {
    stripHtml(html) {
      let tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
    nombreYLugar(item) {
      if (item.tipo === 'parcela') {
        if (!item.site_data) return item.nombre

        const mun_n = item.site_data['municipio-nombre'] || { value: '' }
        //item.site_data.find((el) => el.keyname === 'municipio-nombre') || { value: '' }
        const mun_c = item.site_data['municipio-codigo'] || { value: '' }
        //item.site_data.find((el) => el.keyname === 'municipio-codigo') || { value: '' }
        const poligono = item.site_data.polg || { value: '' }
        // item.site_data.find((elem) => elem.keyname === 'polg') || { value: '' }
        const parcela = item.site_data.parcela || { value: '' }
        //item.site_data.find((elem) => elem.keyname === 'parcela') || { value: '' }
        const recinto = item.site_data.recinto || { value: '' }
        //item.site_data.find((elem) => elem.keyname === 'recinto') || { value: '' }

        const nombre = item.nombre || { value: '' }
        item.site_data.nombre = nombre
        //item.site_data.find((elem) => elem.keyname === 'nombre')
        /* if (!nombre) {
          item.site_data.push({ keyname: 'nombre', value: item.nombre })
        } */

        // return `${item.nombre} -- (Mun: ${mun.value} - Pol: ${poligono.value} - Par: ${parcela.value} - Rec: ${recinto.value}`
        return `${item.nombre} -- ( ${mun_n} / ${poligono} / ${parcela} / ${recinto} )`
      }
    },

    setSite(site) {
      this.item.cultivo = site.nombre
      //site.find((elem) => elem.keyname === 'nombre')?.value
      this.item.municipio = site['municipio-nombre']
      //site.find((elem) => elem.keyname === 'municipio-nombre')?.value
      this.item.poligono = site.polg
      //site.find((elem) => elem.keyname === 'polg')?.value
      this.item.parcela = site.parcela
      //site.find((elem) => elem.keyname === 'parcela')?.value
      this.item.recinto = site.recinto
      //site.find((elem) => elem.keyname === 'recinto')?.value

      this.$forceUpdate()
    },

    emitChange() {
      this.$emit('input', this.grid.rowData)
      this.$emit('change', this.grid.rowData)
    },
    async init() {
      const codigo = 'datos_muestra'
      const body = {
        empresa: this.$auth.user.empresa.uuid,
        alcance: this.auditoria.alcance.uuid,
        codigos: [codigo],
      }
      let element = null
      if (this.offlineMode) {
        const storage = JSON.parse(localStorage.getItem('estaticos'))
        element = storage && storage[codigo]
      } else {
        const res = await this.$store.dispatch('estaticos/list', body)
        if (res && res.length) element = res[0]
        const checklist = await this.$store.dispatch('checklists/list', this.auditoria.uuid)
        if (checklist && checklist.length) this.checklist = checklist[0]
      }

      console.warn('element', element)
      if (element) {
        this.grid.title = element.codigo_nombre

        const items = element.valor
        const columnDefs = []

        columnDefs.push({
          headerName: '#',
          sortable: true,
          filter: true,
          headerCheckboxSelection: false,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          valueGetter: (params) => params.node.rowIndex + 1,
        })

        for (let i = 0; i < items.length; i++) {
          let element = items[i]
          if (element.texto === 'Fecha' && this.isQuesoManchego) {
            element.texto = 'Fecha de elaboración'
          }
          let obj = {
            headerName: element.texto,
            field: element.valor,
            sortable: true,
            filter: true,
            resizable: true,
            editable: true,
          }

          if (element.valor == 'fecha' || element.valor == 'fechaMuestra') {
            obj.cellRenderer = (data) => {
              return this.$moment(data.value).format('D [de] MMMM, YYYY')
            }
          }
          columnDefs.push(obj)
        }

        if (this.isQuesoManchego) {
          // columnDefs.push({
          //   headerName: 'Cumple',
          //   field: 'cumple',
          //   cellRendererFramework: 'CheckboxRenderer',
          //   cellRendererParams: {
          //     disabled: this.auditoriaCerrada,
          //   },
          // })
          // columnDefs.push({
          //   headerName: 'Pregunta relacionada',
          //   field: 'checklist_pregunta',
          // })
        }

        if (this.isOceGlobal) {
          columnDefs.push({
            headerName: 'Cultivo',
            field: 'cultivo',
          })

          columnDefs.push({
            headerName: 'Municipio',
            field: 'municipio',
          })

          columnDefs.push({
            headerName: 'Polígono',
            field: 'poligono',
          })

          columnDefs.push({
            headerName: 'Parcela',
            field: 'parcela',
          })

          columnDefs.push({
            headerName: 'Recinto',
            field: 'recinto',
          })
        }

        this.grid.columnDefs = columnDefs
        this.fields = items
      }
      this.grid.rowData = this.value
      this.grid.api.refreshCells()
      await this.getEstaticos()
    },
    onGridReady(api) {
      this.grid.api = api
    },
    close() {
      this.editIndex = -1
      this.item = {}
      this.dialog = false
    },
    async getEstaticos() {
      const body = {
        alcance: this.auditoria.alcance.uuid,
        codigos: ['datos_muestra', 'laboratorios', 'tipos_analisis'],
      }
      this.estaticos = await this.$store.dispatch('estaticos/list', body)
    },
    editItem(item) {
      this.editIndex = this.grid.rowData.indexOf(item)
      this.item = item ? Object.assign({}, item) : {}
      this.item.codigo_muestra = this.codigo
      this.item.fecha = this.item.fecha ? new Date(this.item.fecha).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
      this.item.fechaMuestra = this.item.fechaMuestra ? new Date(this.item.fechaMuestra).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
      if (this.isCerticalidad) {
        if (item && item.codigo_muestra) this.item.codigo_muestra = item.codigo_muestra
      }
      if (this.isQuesoManchego) {
        if (!this.item.cumple) this.item.cumple = false
        if (item && item.identificacion_muestra) this.item.identificacion_muestra = item.identificacion_muestra
      }

      this.dialog = true
    },
    saveItem() {
      this.editIndex > -1 ? this.editRow() : this.addRow()
    },
    addRow() {
      if (!this.item.cumple) this.item.cumple = false
      this.grid.rowData.push(this.item)
      this.grid.api.updateRowData({ add: [this.item] })
      this.close()
      this.emitChange()
    },
    editRow() {
      this.grid.rowData.splice(this.editIndex, 1, this.item)
      this.close()
      this.emitChange()
    },
    removeRow() {
      const selectedRows = this.grid.api.getSelectedRows()
      if (selectedRows && selectedRows.length) {
        selectedRows.forEach((element) => {
          const index = this.grid.rowData.indexOf(element)
          this.grid.rowData.splice(index, 1)
        })
      }
      this.grid.api.updateRowData({ remove: this.grid.api.getSelectedRows() })
      this.emitChange()
    },
  },
  created() {
    this.init()
  },
}
</script>
