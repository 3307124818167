<template>
  <div>

    <loading v-model="$store.getters.loading" text="Espere por favor ..." />

    <!--    OVERLAY-->
    <v-overlay :value="overlay" class="text-center">
      <h4>Expediente desactivado
        <v-icon>mdi-alert-octagon</v-icon>
      </h4>
      <br>
      <v-btn color="success" @click="updateActivarDesactivar(true)">
        ACTIVAR EXPEDIENTE
      </v-btn>

    </v-overlay>

    <v-tabs v-model="tab" :color="currentColor" :slider-color="currentColor">
      <v-tab v-for="item in tabs" :key="item.id" :href="'#' + item">
        <span class="primary--text">{{ item }}</span>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-row v-if="(auth.solicitudes && auth.solicitudes.canList) || isFreelanceCcl" align="center"
      v-show="tab !== 'comunicaciones' && tab !== 'historico' && tab !== 'marcas'">

      <!-- Year -->
      <v-col cols="2" xl="1" class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" small depressed v-on="on" class="mt-2 mb-2">
              {{ iteracionYearSelected }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group mandatory v-model="iteracionYearSelected">
              <v-list-item v-for="year in iteracionYears" :key="year" :value="year" class="mt-2 mb-2">
                <v-list-item-title>{{ year }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>

      <!-- Iteraciones -->
      <v-col cols="10" xl="11">
        <template v-for="year in iteracionYears">
          <v-slide-group show-arrows :key="year" v-if="year === iteracionYearSelected">
            <v-slide-item v-for="(iteracion, i) in item.iteraciones" :key="i" class="mr-5">
              <v-btn v-if="iteracionMatchYear(i, year)" v-model="iteracionSelected"
                :class="{ 'primary': iteracion.uuid === iteracionSelected.uuid }" small depressed rounded
                @click="updateDatosIteracion(iteracion)">
                <v-icon small class="mr-2">mdi-calendar</v-icon>
                {{ iteracion.codigo }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </template>
      </v-col>

      <v-col cols="12 py-0 px-9" v-if="tab !== 'solicitud'">
        <v-checkbox class="pa-0 ma-0" label="Ver todas las iteraciones"
          @click="iteracionAll = !iteracionAll"></v-checkbox>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-tabs-items v-model="tab">
      <v-tab-item :value="'solicitud'">
        <div v-show="tab === 'solicitud'">

          <v-container>
            <v-row no-gutters>

              <v-col cols="10">
                <v-row no-gutters>
                  <v-col cols="3">
                    <v-switch color="success" v-model="item.activo" label="Activo" :disabled="isDisabled"
                      @change="updateActivarDesactivar" />
                  </v-col>
                  <v-col cols="3">
                    <v-subheader class="pl-0 info-expediente">Estado</v-subheader>
                    <h4 v-if="item.estado" v-text="item.estado" style="float: left;" class="mr-1"></h4>
                    <v-icon color="green" small>mdi-circle</v-icon>
                  </v-col>
                  <v-col cols="4">
                    <v-subheader class="pl-0 info-expediente">Código</v-subheader>
                    <v-text-field v-model="item.codigo" dense outlined hide-details @change="updateCodigo()"
                      :disabled="isDisabled"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-subheader class="pl-0 info-expediente">Fecha dictamen</v-subheader>
                    <h4 v-text="item.fecha_baja"></h4>
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="3">
                    <v-subheader class="pl-0 info-expediente">Cliente</v-subheader>
                    <h4>{{ ui.f.fullName({ cliente: item.cliente, contacto: item.contacto }, { default: '' }) }}</h4>
                  </v-col>
                  <v-col cols="3">
                    <v-subheader class="pl-0 info-expediente">Alcance</v-subheader>
                    <h4 v-if="item.alcance" v-text="item.alcance.nombre"></h4>
                  </v-col>
                  <v-col cols="3"
                    v-if="iteracionSelected.grupo_solicitud && iteracionSelected.grupo_solicitud.grupo_solicitudes_data.length > 0">
                    <v-subheader class="pl-0 info-expediente">Cultivo</v-subheader>
                    <h4 class="text-capitalize"
                      v-text="iteracionSelected.grupo_solicitud.grupo_solicitudes_data[0].value"></h4>
                  </v-col>

                  <v-col cols="3" class="pr-3"
                    v-if="isQuesoManchego && currentSolicitud.tipo_solicitud && currentSolicitud.tipo_solicitud == 'inicial'">
                    <v-menu v-model="ui.showPicker" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field dense outlined hide-no-data hide-details="auto"
                          :value="iteracionSelected.elaboracion_4lote ? $moment(iteracionSelected.elaboracion_4lote).format('DD-MM-YYYY') : ''"
                          label="Fecha de elaboración del 4º lote" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker first-day-of-week="1" v-model="iteracionSelected.elaboracion_4lote"
                        @input="ui.showPicker = false; updateFechaBloqueada(true)"></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="3" v-if="iteracionSelected.bloqueado_hasta">
                    <div>
                      <v-menu :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                          <!-- <h4 class="text-capitalize" v-text="formatDate(iteracionSelected.bloqueado_hasta)" v-on="on"></h4> -->
                          <v-text-field label="Auditoria bloqueada hasta"
                            :value="formatDate(iteracionSelected.bloqueado_hasta)" readonly dense outlined hide-details
                            v-on="on" :disabled="isDisabled"></v-text-field>
                        </template>
                        <v-date-picker v-model="iteracionSelected.bloqueado_hasta" color="primary" :first-day-of-week="1"
                          @change="updateFechasIteracion()"></v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <!-- General Actions -->
              <v-col cols="2">
                <v-row no-gutters justify="end">
                  <v-col cols="12" class="text-right">
                    <!-- Crear auditoria -->
                    <v-tooltip bottom v-if="auth.auditorias && auth.auditorias.canCreate">
                      <template v-slot:activator="{ on }">
                        <v-btn class="mr-2 white--text" color="success" fab small depressed :disabled="existChanges"
                          @click="dialogVisitaAuditoria = true" v-on="on">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Crear Auditoria</span>
                    </v-tooltip>

                    <!-- Discard -->
                    <v-tooltip bottom v-if="existChanges">
                      <template v-slot:activator="{ on }">
                        <v-btn class="mr-2 white--text" color="error" fab small depressed @click="getOne()" v-on="on">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Descartar</span>
                    </v-tooltip>

                    <!-- Save -->
                    <v-tooltip bottom v-if="auth.expedientes.canEdit">
                      <template v-slot:activator="{ on }">
                        <v-btn class="white--text" color="secondary2" fab small depressed @click="saveChanges()"
                          v-on="on">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                      </template>
                      <span>Guardar</span>
                    </v-tooltip>
                  </v-col>

                  <!-- Alerts -->
                  <v-expand-transition>
                    <v-col cols="12" class="pt-4 text-right" v-if="!existChanges && noChanges">
                      <span class="px-3 py-2 warning--text" style="background-color: #F7E5E6; border-radius: 4px">
                        <v-icon color="warning" class="mr-4 pb-1">mdi-alert</v-icon>
                        No existen cambios a guardar
                      </span>
                    </v-col>
                  </v-expand-transition>
                  <!-- -->
                  <v-expand-transition>
                    <v-col cols="12" class="pt-4 text-right" v-if="existChanges">
                      <span class="px-3 py-2 error--text" style="background-color: #F7E5E6; border-radius: 4px">
                        <v-icon color="error" class="mr-4 pb-1">mdi-alert</v-icon>
                        Existen cambios sin guardar
                      </span>
                    </v-col>
                  </v-expand-transition>
                </v-row>
              </v-col>

            </v-row>
          </v-container>

          <v-divider></v-divider>

          <expediente-sites height="40vh" :sites="iteracionSelected.sites" :editable="editable_headers" :actions="actions"
            :gridOptions="gridOptions" multiple @selection="updateSelection($event)" @action="executeRowAction($event)"
            @changesGrid="changesGrid = true" :refreshCells="component" />

        </div>
      </v-tab-item>

      <v-tab-item :value="'auditorias'">
        <auditorias-listado :iteracion="iteracionAll ? {} : iteracionSelected" :expediente="item.uuid"
          v-if="tab === 'auditorias' && item && iteracionSelected" />
      </v-tab-item>

      <v-tab-item :value="'revisiones'">
        <revisiones-listado :iteracion="iteracionAll ? {} : iteracionSelected" :uuid="item.uuid"
          v-if="tab === 'revisiones' && item && iteracionSelected" />
      </v-tab-item>

      <v-tab-item :value="'dictamenes'">
        <dictamenes-listado :iteracion="iteracionAll ? {} : iteracionSelected" :uuid="item.uuid"
          v-if="tab === 'dictamenes' && item && iteracionSelected" />
      </v-tab-item>

      <v-tab-item :value="'certificados'">
        <certificados-listado :iteracion="iteracionAll ? {} : iteracionSelected" :cliente="item.cliente.uuid"
          :expediente="item.uuid" v-if="tab === 'certificados' && item && iteracionSelected" />
      </v-tab-item>

      <!-- <v-tab-item :value="'comunicaciones'">
        <comunicaciones-externas-listado :iteracion="iteracionSelected" :expediente="item" :iteracionSelected="iteracionSelected" v-if="tab === 'comunicaciones' && item && iteracionSelected"/>
      </v-tab-item> -->

      <v-tab-item :value="'marcas'" class="ma-3 pa-3">
        <marcas-listado :iteracion="iteracionSelected" :expediente="item" :cliente="item.cliente"
          :iteracionSelected="iteracionSelected" v-if="tab === 'marcas' && item && iteracionSelected" />
      </v-tab-item>

      <v-tab-item :value="'historico'">
        <template v-if="item && item.alcance">
          <bloques ref="bloques" :alcance="item.alcance.uuid" :modulo="'expedientes'" :modulo_ref="item.uuid">
          </bloques>
        </template>
      </v-tab-item>

    </v-tabs-items>


    <!-- Actions Modal -->
    <actions-modal v-model="dialog" :item="action" :rowIndex="rowIndex" @close="closeAction()"
      @changes="actionChanges($event)" @discard="getOne()" @save="saveAction()" />

    <!-- Create Auditoria Dialog Modal -->
    <create-auditoria-modal v-model="dialogVisitaAuditoria" :cliente="item.cliente" :iteracion="iteracionSelected"
      :bloqueado_hasta="iteracionSelected.bloqueado_hasta" :alcance="item.alcance" :sites="iteracionSelected.sites"
      :gridOptions="gridOptions" @close="dialogVisitaAuditoria = false" @save-auditoria="saveAuditoria($event)" />

  </div>
</template>

<script>
/**
 * @project: certiapp-nuxt
 * @file:    expedientes/default.vue
 * @desc:    ...
 * -------------------------------------------
 * Created Date: 29th October 2021
 * Last Modified: Tue Jul 18 2023
 **/

import format from '~/util/formatHelper'

import AgDatatableSelectable from '~/components/AgDatatableSelectable'
import Loading from '~/components/Loading'
import Confirmation from '~/components/Confirmation'
import COLORS from '~/models/colors'
// Forms
import SelectAlcance from '~/components/forms/SelectAlcances'
import SelectClientes from '~/components/forms/SelectClientes'
import SelectProvincia from '~/components/forms/SelectProvincia'
import FloatingButtonMenu from '~/components/FloatingButtonMenu'
//
import ExpedienteVariables from '~/components/customVariables/CustomVariables'
import ActionsModal from '~/components/expedientes/ActionsModal'
import CreateAuditoriaModal from '~/components/auditorias/CreateAuditoriaModal'

// Listados
import MarcasListado from '~/pages/marcas/index'
import AuditoriasListado from '~/pages/auditorias/list'
import RevisionesListado from '~/pages/revisiones/list'
import DictamenesListado from '~/pages/dictamenes/list'
import CertificadosListado from '~/pages/certificados/list'
import ComunicacionesExternasListado from '~/pages/comunicaciones_externas/index'

// Actions Multiple
import Sites from '~/components/solicitudes/sites'
// import ParcelasActionsMultiple from "~/components/expedientes/coplaca/ExpedienteCoplacaParcelasActionsMultiple";
import ExpedienteSites from '~/components/expedientes/sites'

import bloques from '~/components/bloques/section'

export default {
  components: {
    ExpedienteSites,
    Sites,
    bloques,
    CreateAuditoriaModal,
    Confirmation,
    Loading,
    AgDatatableSelectable,
    SelectAlcance,
    SelectClientes,
    SelectProvincia,
    FloatingButtonMenu,
    ExpedienteVariables,
    ActionsModal,
    MarcasListado,
    AuditoriasListado,
    RevisionesListado,
    DictamenesListado,
    CertificadosListado,
    ComunicacionesExternasListado,
  },
  data: () => ({
    component: 0,
    dialog: false,
    noChanges: false,
    changesGrid: false,
    colors: COLORS,
    grids: {},

    action: {
      action: '',
      selection: [],
    },

    actions: [
      // {
      //   tipo: "productor", action: "ExpedienteCoplacaProductoresActionsCellRenderer"
      // },
      // { tipo: "parcela", action: "ExpedienteCoplacaParcelasActionsCellRenderer", multiple: ParcelasActionsMultiple }
    ],
    editable_headers: [
      // { tipo: "productor", headers: ["nombre_productor", "cambio_titular"] },
      // { tipo: "parcela", headers: ["cod_provincia", "cod_municipio", "cambio_titular"] }
    ],
    gridOptions: {
      // productor : {
      //   getRowStyle: params => {
      //     if (params.data.cambio_titular) return { background: '#dfdfff' }
      //   }
      // },
      // parcela : {
      //   getRowStyle: params => {
      //     if (params.data.cambio_titular) return { background: '#dfdfff' }
      //   }
      // }
    },
    //
    overlay: false,
    dialogVisitaAuditoria: false,
    rowIndex: [],
    globalgapActions: [
      // {name: 'Baja', action: 'baja', tipo_fichero: ['CENTRALES', 'PRODUCTORES']},
      // {name: 'Cambio superficie', action: 'cambio_superficie', tipo_fichero: ['PRODUCTOS']},
      // {name: 'Cambio cultivo', action: 'cambio_cultivo', tipo_fichero: ['PRODUCTOS']},
      // {name: 'Cambio GGN', action: 'cambio_ggn', tipo_fichero: ['PRODUCTORES']},
      // {name: 'Cambio GLN', action: 'cambio_gln', tipo_fichero: ['PRODUCTORES']},
      // {name: 'Cambio NIF', action: 'cambio_nif', tipo_fichero: ['PRODUCTORES']},
      // {name: 'Eliminar', action: 'eliminar', tipo_fichero: ['PRODUCTORES', 'PRODUCTOS']}
    ],
    item: { iteraciones: [], grupo_alcance: {} },
    iteracionYears: [],
    iteracionYearSelected: {},
    iteracionSelected: {},
    iteracionAll: false,

    auth: {
      auditorias: {},
      revisiones: {},
      dictamenes: {},
      solicitudes: {},
      expedientes: {},
      certificados: {},
    },

    ui: {
      f: format,
      showPicker: false,
    },
  }),
  beforeMount() {
    this.frameworkComponents = {
      BooleanCellEditor: 'BooleanCellEditor',
      AutocompleteCellEditor: 'AutocompleteCellEditor',
      SelectCellEditor: 'SelectCellEditor',
      TextCellEditor: 'TextCellEditor',
      DateCellEditor: 'DateCellEditor',
    }
    this.excelStyles = [
      {
        id: 'default',
        dataType: 'string',
      },
    ]
  },
  mounted() {
    this.getOne()
    this.checkRoles()
  },

  watch: {
    tab(val) {
      if (val === 'solicitud') this.getOne()
    },
  },

  computed: {
    isFreelanceCcl() {
      return this.$auth.user.empresa.codigo === 'ccl' && this.$auth.user.roles.includes('ROLE_AUDITOR_FREELANCE')
    },
    isDisabled() {
      if (this.auth?.expedientes?.canEdit) return false
      return true
    },

    isQuesoManchego() {
      return this.item?.alcance?.codigo == 'queso-manchego'
    },

    tab: {
      get() {
        return this.$store.state.expedientes.tab
      },
      set(val) {
        this.$store.commit('expedientes/SET_TAB', val)
      },
    },

    tabs() {
      let tabs = [
        'solicitud',
        'auditorias',
        'revisiones',
        'dictamenes',
        'certificados',
        //'comunicaciones',
        'historico',
      ]

      if (this.isQuesoManchego) {
        tabs.push('marcas')
      }

      if (!this.auth.auditorias.canList) tabs.splice(tabs.indexOf('auditorias'), 1)
      if (!this.auth.revisiones.canList) tabs.splice(tabs.indexOf('revisiones'), 1)
      if (!this.auth.dictamenes.canList) tabs.splice(tabs.indexOf('dictamenes'), 1)
      if (!this.auth.certificados.canList) tabs.splice(tabs.indexOf('certificados'), 1)

      return tabs
    },

    changesTable() {
      return this.$store.state.expedientes.changesTable
    },

    existChanges() {
      return this.changesTable.length > 0 || this.changesGrid
    },

    currentColor() {
      const idx = this.tabs.indexOf(this.tab)
      return idx ? this.colors[idx] : 'secondary2'
    },

    currentSolicitud() {
      let solicitud = {}
      if (this.iteracionSelected?.grupo_solicitud?.solicitudes) {
        if (this.iteracionSelected?.grupo_solicitud?.solicitudes[0].data) {
          solicitud = this.iteracionSelected?.grupo_solicitud?.solicitudes[0].data
        }
      }
      return solicitud
    },
  },
  methods: {
    async loadFormatosData(concesion) {
      this.$store.commit('formatos/SET_MODULO', 'expedientes')
      this.$store.commit('formatos/SET_MODULO_REF', this.item.uuid)
      this.$store.commit('formatos/SET_ALCANCE', this.iteracionSelected.alcance)

      // Formato Tipos
      this.$store.commit('formatos/GET_TIPOS', this.$auth.user.empresa.codigo)

      // Formato Data
      const body = {
        ...this.item,
        cliente: cliente,
      }

      // Cliente
      const cliente = await this.$store.dispatch('clientes/get', this.item.cliente.uuid)
      if (cliente) body.cliente = cliente

      // Solicitud
      const solicitud = await this.$store.dispatch(
        'grupo_solicitudes/get',
        this.iteracionSelected.grupo_solicitud.uuid
      )
      if (solicitud) body.solicitud = solicitud

      // Datos
      const datos = []
      datos.push(body)
      this.$store.commit('formatos/SET_DATOS', datos)

      // PDF
      // let route = '/formatos?plantilla='
      // route += concesion ? 'concesion' : 'informe'
      // this.$router.push(route)

      // New v2 formatos load request
      $nuxt.$emit('formatos:load', {
        alcance: this.iteracionSelected.alcance,
      })
    },
    updateSelection(selection) {
      this.action.selection = selection
    },
    async updateCodigo() {
      const body = {
        ...this.item,
      }
      await this.$store.dispatch(`expedientes/updateCodigo`, body)
    },

    async updateActivarDesactivar(activate) {
      if (activate) {
        await this.$store.dispatch('expedientes/activar', this.item.uuid)
        this.item.activo = true
        this.overlay = false
      } else {
        const res = await this.$store.dispatch('expedientes/desactivar', this.item.uuid)
        if (res) {
          // this.item.activo = false;
          // this.overlay = true;
          this.$router.push('/expedientes')
          this.$store.commit('notification/show', {
            text: 'Expediente desactivado correctamente',
            color: 'success',
          })
        }
      }
    },
    async saveAuditoria(auditoria) {
      await this.$store.dispatch(`expedientes/createAuditoria`, {
        iteracion_uuid: this.iteracionSelected.uuid,
        expediente_uuid: this.item.uuid,
        auditoria: auditoria,
      })
      this.dialogVisitaAuditoria = false
      this.$store.commit('notification/show', {
        text: 'Auditoria creada correctamente',
        color: 'success',
      })
      this.getOne()
    },
    formatDate(date) {
      return date ? this.$moment(date).format('DD-MM-YYYY') : ''
    },
    async executeRowAction(event) {
      if (event.selection && event.selection.length) {
        this.action = event
        this.dialog = true
      } else {
        this.noSelection = true
        setTimeout((this.noSelection = false), 10000)
      }
    },
    actionChanges(event) {
      const action = this.action.action
      const row = this.action.row
      const selection = this.action.selection
      const uuids = selection.map((element) => element.uuid)
      const field = this.action.field
      const tipo = this.action.tipo
      const newIteration = event.newIteration
      let newValue = event.newValue
      let changes = {
        table: [],
        changes: {},
      }
      let sites = this.iteracionSelected.sites
      for (let i = 0; i < selection.length; i++) {
        const element = selection[i]
        // Change value on grid
        if (action !== 'eliminar') {
          for (let k = 0; k < sites.length; k++) {
            const row = sites[k]
            if (row.uuid === element.uuid) {
              for (let n = 0; n < row.site_data.length; n++) {
                const keyname = row.site_data[n].keyname
                if (keyname === field) row.site_data[n].value = newValue
              }
            }
          }
        }
        this.iteracionSelected.sites = sites
        // Push changes
        changes.table.push({
          newIteration: newIteration,
          tipo: tipo,
          row: row[element.uuid],
          field: field,
          action: this.action.name,
          value: newValue,
        })
      }
      changes.changes = {
        newIteration: newIteration,
        tipo: tipo,
        iteracion: this.iteracionSelected.uuid,
        uuids: uuids,
        action: 'coplaca.' + tipo + '.' + action,
        value: newValue,
      }
      this.closeAction(changes)
    },
    closeAction(changes) {
      if (changes) {
        this.component++
        this.$store.commit('expedientes/updateChangesActions', changes.changes)
        this.$store.commit('expedientes/updateChangesTable', changes.table)
      }
      this.dialog = false
      this.action = {
        action: '',
        selection: [],
      }
      this.rowIndex = []
    },
    saveChanges() {
      if (this.changesTable.length > 0) {
        this.action = {
          action: 'save',
          selection: this.action.selection,
        }
        this.dialog = true
      } else {
        this.noChanges = true
        setTimeout(() => {
          this.noChanges = false
        }, 10000)
      }
    },
    async saveAction() {
      this.dialog = false
      const res = await this.$store.dispatch(
        'expedientes/saveChanges',
        this.$auth.user.empresa.codigo
      )
      if (res && !res.error)
        this.$store.commit('notification/show', {
          text: 'Expediente guardado correctamente',
          color: 'success',
          setTimeout: 3000,
        })
      this.getOne()
    },
    iteracionMatchYear(i, year) {
      return this.$moment(this.item.iteraciones[i].codigo, 'DD/MM/YYYY').format('YYYY') === year
    },
    async getOne() {
      this.submenuItem = 0
      this.changesGrid = false
      this.$store.commit('expedientes/resetQueueChanges')
      const res = await this.$store.dispatch('expedientes/expediente', this.$route.params.uuid)
      if (res) {
        this.item = res
        this.$store.commit('listas_validacion/SET_ALCANCE', this.item.alcance.uuid)
        if (this.item.activo === false) this.overlay = true
        let reverse = this.item.iteraciones.reverse()
        for (let i = 0; i < reverse.length; i++) {
          let date = this.$moment(reverse[i].codigo, 'DD/MM/YYYY').format('YYYY')
          if (!this.iteracionYears.includes(date)) this.iteracionYears.push(date)
        }
        this.iteracionYearSelected = this.$moment(reverse[0].codigo, 'DD/MM/YYYY').format('YYYY')
        this.item.iteraciones = reverse
        // Obtener Dropdowns y Tipos de Ficheros
        await this.$store.dispatch('dropdowns/list', {
          empresa: this.$auth.user.empresa.uuid,
          grupo_alcance: this.item.grupo_alcance.uuid,
        })
        let body = {
          grupo_alcance: this.item.grupo_alcance.uuid,
          empresa: this.$auth.user.empresa.uuid,
        }
        if (this.item.subalcance) body.subalcance = this.item.subalcance.uuid
        const tipos_ficheros = await this.$store.dispatch('tipos_fichero/list', body)
        if (tipos_ficheros) this.tipos_ficheros = tipos_ficheros

        await this.updateDatosIteracion(this.item.iteraciones[0])
      }
      this.component++
    },
    async updateDatosIteracion(iteracion) {
      const res = await this.$store.dispatch('expedientes/iteracion', [
        this.item.uuid,
        iteracion.uuid,
      ])
      if (res) {
        const data = res

        /* if(this.isQuesoManchego){
          data.bloqueado_hasta = data.bloqueado_hasta ? this.$moment(data.bloqueado_hasta).format('YYYY-MM-DD') : ''
          data.elaboracion_4lote = data.elaboracion_4lote ? this.$moment(data.elaboracion_4lote).format('YYYY-MM-DD') : ''
        }else{ */
        data.bloqueado_hasta = this.$moment(data.bloqueado_hasta).format('YYYY-MM-DD')
        data.elaboracion_4lote = this.$moment(data.elaboracion_4lote).format('YYYY-MM-DD')
        //}
        //ordenar data.sites por parejas que deben ir juntas ejemplo instalacion propia y actividad propia, instalacion subcontratada y actividad subcontratada
        let sites = data.sites.sort()
        let sitesOrdered = []

        sites.forEach((site) => {
          if (site.tipo.includes('instalacion propia')) {
            sitesOrdered.push(site)
            let siteActividad = sites.find((s) => s.tipo.includes('actividad propia'))
            if (siteActividad) {
              console.log('siteActividad', siteActividad)
              if (!sitesOrdered.find((s) => s.uuid == siteActividad.uuid)) sitesOrdered.push(siteActividad)
            }
          }
          if (site.tipo.includes('instalacion subcontratada')) {
            sitesOrdered.push(site)
            let siteActividad = sites.find((s) => s.tipo.includes('actividad subcontratada'))
            if (siteActividad) {
              if (!sitesOrdered.find((s) => s.uuid == siteActividad.uuid)) sitesOrdered.push(siteActividad)
            }
          }
          if (site.tipo.includes('instalacion maquila')) {
            sitesOrdered.push(site)
            let siteActividad = sites.find((s) => s.tipo.includes('actividad maquila'))
            if (siteActividad) {
              if (!sitesOrdered.find((s) => s.uuid == siteActividad.uuid)) sitesOrdered.push(siteActividad)
            }
          }
          //sino esta presente en el array de sitesOrdered lo añadimos
          if (!sitesOrdered.find((s) => s.uuid == site.uuid)) sitesOrdered.push(site)


        })

        data.sites = sitesOrdered
        //data.sites= sites



        this.iteracionSelected = data

        this.loadFormatosData()
      }
    },

    //+-------------------------------------------------
    // updateFechaBloqueada()
    // Sets fecha_4lote and fecha_bloqueada_hasta
    // -----
    // Created on Tue Jul 19 2022
    //+-------------------------------------------------
    async updateFechaBloqueada(store = false) {
      if (this.isQuesoManchego && this.currentSolicitud?.tipo_solicitud == 'inicial') {
        if (!this.iteracionSelected.elaboracion_4lote) {
          this.iteracionSelected.elaboracion_4lote = this.$moment().format('YYYY-MM-DD')
        }

        this.iteracionSelected.bloqueado_hasta = this.$moment(
          this.iteracionSelected.elaboracion_4lote
        )
          .add(3, 'months')
          .format('YYYY-MM-DD')
      }

      if (store) this.updateFechasIteracion()
    },

    async updateFechasIteracion() {
      let body = {
        uuid: this.iteracionSelected.uuid,
        bloqueado_hasta: this.iteracionSelected.bloqueado_hasta,
        elaboracion_4lote: this.iteracionSelected.elaboracion_4lote,
      }
      await this.$store.dispatch(`expedientes/updateFechasIteracion`, body)
    },

    async checkRoles() {
      this.auth.auditorias = await this.$store.dispatch('user/can', {
        module: 'auditorias',
        submodule: 'auditorias',
        return: 'all',
      })

      this.auth.solicitudes = await this.$store.dispatch('user/can', {
        module: 'solicitudes',
        submodule: 'solicitudes',
      })

      this.auth.revisiones = await this.$store.dispatch('user/can', {
        module: 'revisiones',
        submodule: 'revisiones',
      })

      this.auth.dictamenes = await this.$store.dispatch('user/can', {
        module: 'dictamenes',
        submodule: 'dictamenes',
      })

      this.auth.certificados = await this.$store.dispatch('user/can', {
        module: 'certificados',
      })

      this.auth.expedientes = await this.$store.dispatch('user/can', {
        module: 'expedientes',
      })
    },
  },
}
</script>

<style>
.toolbar-expediente-width-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.09) !important;
  z-index: 1;
  right: 0;
  top: 60px;
  left: 275px;
  position: fixed;
}

.ag-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.toolbar-expediente-no-menu {
  background: #f5f5f5 !important;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.09) !important;
  color: #727891 !important;
  z-index: 1;
  right: 0;
  left: 0;
  top: 60px;
  position: fixed;
}

.info-expediente {
  height: 30px;
}
</style>
