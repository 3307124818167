export const state = () => ({
  list: [],
  item: {},
})

//commit mutations
export const mutations = {
  GET_LIST(state) {
    return this.list
  },
  SET_LIST(state, items) {
    this.list = items
  },
}

//dispatch actions
export const actions = {
  async getNoConformidades({ commit }) {
    const res = await this.$axios.get('/informes/no-conformidades')
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },
  async getExpedientesActivos({ commit }) {
    const res = await this.$axios.get('/informes/expedientes-activos')
    if (res) {
      commit('SET_LIST', res.data)
      return res.data
    }
  },
}
